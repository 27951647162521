<template>
  <div class="suggestManage">
    <div class="page-title">意见反馈</div>
    <div class="row-me row-center flex-wrap mT20">
      <div>反馈用户</div>
      <div class="w300 mL10">
        <el-input placeholder="请输入反馈用户昵称、手机号" style="width: 100%;" clearable v-model="name"></el-input>
      </div>
      <div class="mL20">反馈时间</div>
      <div class="w400 mL10">
        <el-date-picker type="datetimerange" range-separator="至" start-placeholder="开始时间"
                        value-format="YYYY-MM-DD HH:mm:ss"
                        end-placeholder="结束时间" style="width: 100%;" clearable v-model="date">
        </el-date-picker>
      </div>
      <div class="btn-blue w100 btn-height40 mL20" @click="clickSearch">查询</div>
      <div class="btn-blank w100 btn-height40 mL20" @click="clickClear">重置</div>
    </div>
    <div class="column-me mT20">
      <div>
        <el-table :data="tableData" border header-cell-class-name="table-header-style"
                  cell-class-name="table-cell-style">
          <el-table-column type="index" label="序号" width="80" align="center"/>
          <el-table-column prop="id" label="ID" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="user.nickname" label="用户" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="user.phone" label="手机号" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="content" label="反馈内容" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="createdTime" label="反馈时间" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" align="center" show-overflow-tooltip width="150" fixed="right">
            <template #default="scope">
              <div class="row-me center-all">
                <el-popconfirm title="您确认删除吗？" width="250" @confirm="clickDelete(scope.row)">
                  <template #reference>
                    <div class="red-text">删除</div>
                  </template>
                </el-popconfirm>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="mT10">
        <el-pagination @current-change="handleCurrentChange" :current-page.sync="pageNo"
                       layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
      name: '',
      date: [],
      tableData: [],
      pageNo: 1,
      total: 0,
      detailItem: {},
      visible: false,
      editor: null,
    }
  },
  // 侦听器
  watch: {},
  // 计算属性
  computed: {},
  // html加载完成之前
  created() {
    this.clickSearch()
  },
  // html加载完成后执行。
  mounted() {

  },
  // 事件方法执行
  methods: {
    clickSearch() {
      this.pageNo = 1
      this.getData()
    },
    clickClear() {
      this.name = ''
      this.date = []
      this.clickSearch()
    },
    //删除
    clickDelete(item) {
      this.$postForm('/feeds/feedbackDeletedAll', {
        ids: [item.id]
      }).then((res) => {
        if (res) {
          this.$message.success('删除成功')
          this.getData()
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    handleCurrentChange(pageNo) {
      this.pageNo = pageNo
      this.getData()
    },
    getData() {
      this.$postForm('/feeds/feedbackPage', {
        name: this.name,
        startTime: this.date.length === 2 ? this.date[0] : '',
        endTime: this.date.length === 2 ? this.date[1] : '',
        pageNumber: this.pageNo,
        pageSize: 10,
      }).then((res) => {
        if (res) {
          this.total = Number(res.data.total)
          this.tableData = res.data.records
        }
      }).catch((error) => {
        console.log(error)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.suggestManage {
  height: 100%;
  margin: 20px;
  padding: 20px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .left-view {
    width: 120px;
    text-align: right;
    margin-right: 20px;
  }
}
</style>
